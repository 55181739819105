import React from 'react'
import { useTranslation } from 'react-i18next'
import { ROUTES } from '~/constants/routes'
import { useLocale } from '~/hooks/useLocale'
import type { ValuesByLocale } from '~/i18n/config'
import ParallaxSection from '../ParallaxSection'
import CheckUpImageEN from './en/check-up.jpg'
import CheckUpImageFR from './fr/check-up.jpg'

const FOLLOW_UP_IMAGES: ValuesByLocale<string> = {
  fr: CheckUpImageFR,
  en: CheckUpImageEN
}

const HomeCheckup = React.forwardRef<HTMLDivElement>((_, ref) => {
  const { t } = useTranslation()
  const locale = useLocale()

  return (
    <ParallaxSection
      ParallaxProps={{ translateY: [20, -20] }}
      ref={ref}
      className="bg-black"
      ContentProps={{
        className: 'justify-center md:pt-[8%] max-w-xl 2xl:max-w-3xl'
      }}
      title={t('common:theCheckUp')}
      stepNumber={1}
      imgProps={{
        src: FOLLOW_UP_IMAGES[locale],
        alt: t('common:theCheckUp'),
        className: 'object-top'
      }}
      descriptions={[
        t('home:checkUp.description'),
        t('home:checkUp.moreDescription')
      ]}
      learnMoreTo={ROUTES.checkup}
    />
  )
})

export default HomeCheckup
